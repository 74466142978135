import { http, apiEndPoints } from "../../utils/http";
import { headers } from "../../utils/http/headers";
import axios from "axios";
const getUserType = () => {
  return http.get(apiEndPoints.USER_TYPE, headers);
};

const getLanguages = () => {
  return http.get(apiEndPoints.GET_LANGUAGE, headers);
};

const validateUser = (userName, userTypeId) => {
  return http.get(
    `${apiEndPoints.VALIDATE_USER}/${userName}/${userTypeId}`,
    headers
  );
};
const getToken = (data) => {
  return http.post(apiEndPoints.GET_TOKEN, data);
};

const sendOtp = (mobileNo) => {
  return http.post(`${apiEndPoints.SEND_OTP}/${mobileNo}`, headers);
};

const verifyOtp = (mobileNo, otp) => {
  return http.post(`${apiEndPoints.VERIFY_OTP}/${mobileNo},${otp}`, headers);
};

const login = (data) => {
  return http.post(apiEndPoints.LOGIN, data);
};
const loginWithOtp = (data) => {
  return http.post(apiEndPoints.LOGIN_WITH_OTP, data);
};

const register = (userName, userTypeId, password, MobileNo) => {
  return http.post(
    `${apiEndPoints.REGISTER}/${userName},${userTypeId},${password},${MobileNo}`,
    headers
  );
};

const forgotPass = (Password) => {
  var data = localStorage.getItem("id");
  localStorage.removeItem("id");
  var config = {
    method: "post",
    url: `https://api.rupantar.in/api/Login/SetPassword?Password=${Password}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config);
};

const auth = {
  getUserType,
  getLanguages,
  validateUser,
  sendOtp,
  verifyOtp,
  login,
  loginWithOtp,
  register,
  forgotPass,
  getToken,
};

export default auth;
