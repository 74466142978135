import { useEffect, useState } from "react";
import ReactIcons from "../icons";
import translation from "../../assets/images/translation.png";
import notification from "../../assets/images/notification.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  languageSelector,
  retrieveLanguages,
} from "../../redux/slices/language/languageSlice";
import { getTranslatedText } from "../../redux/slices/translatedText/getTranslatedText";
const Header = (props) => {
  console.log(props);
  const { hideSidebar, setHideSidebar } = props.tsidebar;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [languageID, setLanguageID] = useState(1);
  const languageData = useSelector(languageSelector);

  const getLanguages = async () => {
    try {
      const dispatchLanguage = await dispatch(retrieveLanguages()).unwrap();
      return dispatchLanguage;
    } catch (error) {
      console.log(error);
    }
  };
  const getText = async () => {
    await dispatch(getTranslatedText(languageID)).unwrap();
  };
  useEffect(() => {
    getLanguages();
  }, []);
  useEffect(() => {
    if (languageID) {
      getText();
    }
  }, [languageID]);
  console.log(languageID);

  const toggleSidebar = () => {
    console.log(hideSidebar, setHideSidebar);
    setHideSidebar(!hideSidebar);
  };
  console.log(hideSidebar);
  return (
    <div className="flex items-center justify-between flex-row md:flex-wrap bg-gray-100 border-slate-300 h-12">
      <div className="flex items-center ml-4">
        <ReactIcons.RxHamburgerMenu
          onClick={toggleSidebar}
          className="cursor-pointer"
        />
        {/* <p className="pl-2.5">Screening</p> */}
      </div>
      <div className="flex md:flex-wrap items-center">
        <div className="flex mr-[20px]">
          <img alt="" src={translation} className="w-[20%]" />
          <select
            className="bg-transparent"
            onChange={(e) => {
              console.log(e.target.value);
              setLanguageID(e.target.value);
            }}
          >
            {/* <option>Select language</option> */}
            {languageData.data?.map((item) => (
              <option
                key={item.id}
                onChange={() => {
                  setLanguageID(item.id);
                }}
                value={item.id}
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="md:ml-8">
          <img alt="" src={notification} className="w-[60%]" />
        </div>
        <div
          className="flex flex-wrap md:mx-16 mx-4 cursor-pointer flex-row items-center"
          onClick={() => {
            navigate("/user/profile");
          }}
        >
          <img
            src={JSON.parse(localStorage.getItem("user")).profilePicURL}
            alt="..."
            className="rounded-full w-5 h-5"
          />
          <p className="ml-2">
            {JSON.parse(localStorage.getItem("user")).firstName}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
