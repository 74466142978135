import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  forgotPassword,
  forgotPasswordSelector,
  getTokenMiddleware,
  loginWithOtp,
  sendOtp,
  userLogin,
  userRegistration,
  validateUser,
  verifyOtp,
} from "../../../redux/slices/auth/authSlice";
import Card from "../../../utils/card/Card";
import Login from "./login/Login";
import SetPassword from "./login/SetPassword";
import OtpScreen from "./OTPInput/OtpScreen";
import UserTypeScreen from "./UserTypeScreen";
import ForgotPassword from "./login/ForgotPassword";
import "react-toastify/dist/ReactToastify.css";
import {
  indianMobileNumber,
  passwordValidation,
  userNameRegx,
} from "../../../utils/Regex/regex";
const Auth = () => {
  const [count, setCount] = useState(0);
  const [otp, setOtp] = useState(null);
  const [user, setUser] = useState("");
  const [showMessage, setShowMessage] = useState(true);
  const [showEnterPass, setShowEnterPass] = useState(false);
  const [callForgotPasswordApi, setCallForgotPasswordApi] = useState(false);
  // const [buttonDisable, setButtonDisable] = useState(false);

  const [forgotPasswordCredential, setForgotPasswordCredential] = useState({
    Password: "",
    confirmPassword: "",
  });
  const [authentication, setAuthentication] = useState({
    userTypeId: "",
    userName: "",
    password: "",
    Password: "",
    confirmPassword: "",
  });
  const { userTypeId, userName, password, Password, confirmPassword } =
    authentication;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector(forgotPasswordSelector);

  const getTokenfterRegistration = async (userName, userTypeId, password) => {
    try {
      const result = await dispatch(
        getTokenMiddleware({ userName, userTypeId, password })
      ).unwrap();
      const { token, status, response } = result;
      const { id } = response;
      if (status === 1) {
        const userId = id;
        localStorage.setItem("id", userId);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(response));
        setShowMessage(true);
        toast.success("Register successfully");
        setTimeout(() => {
          navigate("/user/profile");
        }, 2000);
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (user === "Existing") navigate("/user/dashboard");

    if (count === 0) {
      setCount(1);
    } else if (count === 1) {
      if (callForgotPasswordApi) {
        // setCallForgotPasswordApi(false);
        handleLoginWithOtp("forgotpassword");
        return;
      } else if (!showEnterPass) {
        try {
          if (!userNameRegx.test(authentication.userName)) {
            setShowMessage(true);
            toast.warn(
              "username should contains only alphabet , underscore , @ or 10 digit mobile number"
            );

            return;
          }
          const result = await dispatch(
            validateUser({ userName, userTypeId })
          ).unwrap();

          if (
            !indianMobileNumber.test(authentication.userName) &&
            result.message === "New User"
          ) {
            setShowMessage(true);
            toast.error("Enter the valid Username or 10 digit mobile number");
          } else if (result.isDuplicateUser === 1) {
            const { response } = result;
            const { id } = response;
            localStorage.setItem("id", JSON.stringify(id));
            localStorage.setItem("user", JSON.stringify(response));
            setShowEnterPass(true);
            setUser("Existing");
          } else {
            setShowEnterPass(false);
            setUser("New");
            handleLoginWithOtp("New");
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        const userData = {
          userName: userName,
          password: password,
          userTypeId: userTypeId,
        };
        if (!passwordValidation.test(password)) {
          setShowMessage(true);
          toast.warn("Password only contains alphabet");

          return;
        }
        const response = await dispatch(userLogin(userData)).unwrap();

        if (response.message === "Success") {
          setShowMessage(true);
          toast.success("Successfully login");
          const userId = response.userId;
          const token = response.token;
          localStorage.setItem("token", token);
          localStorage.setItem("id", userId);
          localStorage.setItem("user", JSON.stringify(response.userResponse));
          setTimeout(() => {
            navigate("/user/dashboard");
          }, 1000);
        } else {
          setShowMessage(true);
          toast.error("Invalid credential");
        }
      }
    } else if (count === 2) {
      try {
        if (user === "Existing" && callForgotPasswordApi === false) {
          const response = await dispatch(
            loginWithOtp({ userName, otp, userType: userTypeId })
          ).unwrap();

          const { status, token, userResponse } = response;
          if (status === 1) {
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(userResponse));
            setShowMessage(true);
            toast.success("Successfully login");
            setTimeout(() => {
              navigate("/user/dashboard");
            }, 1000);
          } else {
            setShowMessage(true);
            toast.error("You entered wrong OTP");

            // setTimeout(() => {
            //   setCount(1);
            // }, 3000);
          }
        } else {
          const userName = JSON.parse(localStorage.getItem("user"))?.mobileNo
            ? JSON.parse(localStorage.getItem("user")).mobileNo
            : authentication.userName;
          const response = await dispatch(
            verifyOtp({ userName, otp })
          ).unwrap();

          if (response.message === "Invalid OTP") {
            setShowMessage(true);
            toast.error("Invalid OTP");
          } else if (response.message === "Error! OTP Expire") {
            setShowMessage(true);
            toast.error("Error! OTP Expire");
          } else if (response.status === 1 && user === "New") {
            setShowMessage(true);
            toast.success("OTP is verified successfully");
            setTimeout(() => {
              setCount(3);
            }, 1000);
          } else if (response.status === 1) {
            setShowMessage(true);
            toast.success("OTP is verified successfully");
            setTimeout(() => {
              setCount(4);
            }, 1000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else if (count === 3) {
      if (Password !== confirmPassword) {
        toast.error("Password and confirm password should be same");
      } else {
        try {
          if (!userNameRegx.test(authentication.userName)) {
            if (!indianMobileNumber.test(authentication.userName)) {
              setShowMessage(true);
              toast.warn(
                "username should contains only alphabet , underscore , @ or 10 digit mobile number"
              );

              return;
            }
          } else if (
            !passwordValidation.test(Password) ||
            !passwordValidation.test(confirmPassword)
          ) {
            setShowMessage(true);
            toast.warn("Password only contains alphabet");

            return;
          }
          const MobileNo = localStorage.getItem("mobileno");
          dispatch(
            userRegistration({ userName, userTypeId, Password, MobileNo })
          ).then((response) => {
            const { status } = response.payload;
            const { userName, userTypeId } = response.payload.response;
            if (status === 1) {
              getTokenfterRegistration(userName, userTypeId, Password);
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else if (count === 4) {
      setCallForgotPasswordApi(false);
      const { Password, confirmPassword } = forgotPasswordCredential;
      if (
        !passwordValidation.test(Password) ||
        !passwordValidation.test(confirmPassword)
      ) {
        setShowMessage(true);
        toast.warn("Password only contains alphabet");

        return;
      } else if (Password !== confirmPassword) {
        setShowMessage(true);
        toast.warn("Password and confirm password should be same");

        return;
      } else {
        dispatch(forgotPassword({ Password }))
          .then((res) => {
            if (res.payload.result === true) {
              setShowMessage(true);
              toast.success("Password updated successfully");
              setAuthentication({
                ...authentication,
                password: "",
                confirmPassword: "",
              });
              setTimeout(() => {
                setCount(1);
              }, 1000);
            } else {
              setShowMessage(true);
              toast.warn("Old password and new password can not be same");
              // setAuthentication({
              //   ...authentication,
              //   password: "",
              //   confirmPassword: "",
              // });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setCount(0);
    }
  };

  const handleChange = (e, screen) => {
    if (screen === "userType") {
      setAuthentication((prev) => ({ ...prev, userTypeId: e.target.id }));
    } else if (screen === "login") {
      setAuthentication((prev) => ({ ...prev, userName: e.target.value }));
    } else if (screen === "loginPass") {
      setAuthentication((prev) => ({ ...prev, password: e.target.value }));
    } else if (screen === "setPassword") {
      setAuthentication({ ...authentication, [e.target.name]: e.target.value });
    } else {
      setForgotPasswordCredential({
        ...forgotPasswordCredential,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleLoginWithOtp = async (screen) => {
    const mobileno = JSON.parse(localStorage.getItem("user"))?.mobileNo
      ? JSON.parse(localStorage.getItem("user"))?.mobileNo
      : JSON.parse(localStorage.getItem("user"))?.userName;
    localStorage.setItem("mobileno", mobileno);

    if (!indianMobileNumber.test(mobileno) && screen !== "New") {
      setShowMessage(true);
      toast.error("Please enter a valid number");
      return;
    } else if (screen) {
      localStorage.setItem("mobileno", authentication.userName);
    }
    const response = await dispatch(
      sendOtp(mobileno ? mobileno : authentication.userName)
    ).unwrap();

    if (response.message === "Error! Invalid Mobile No") {
      setShowMessage(true);
      toast.error("Please enter valid mobile number or username");
    } else if (response.status === 1) {
      setShowMessage(true);
      toast.success("OTP is sent your mobile number successfully");
      setTimeout(() => {
        setCount(2);
      }, 2000);
    } else if (response.status === 0) {
      setShowMessage(true);
      toast.error("Unable to sent otp");
      setTimeout(() => {
        setCount(1);
      }, 2000);
    }
  };

  return (
    <>
      <div className="bg-gradient-to-b from-[#002EFF2B] to-[#0DE9FF2B] mx-[20px] lg:mx-[150px] xl:mx-[300px] my-[75px] md:my-[200px] lg:my-[75px] p-4 rounded-md drop-shadow-[0_04px_20px_#00000021]">
        <div className="grid grid-cols-1 md:grid-cols-3 bg-[#FDFDFD] rounded-md p-4 ">
          <div className="col-span-1">
            <Card height={"[50px]"} color="#101942" className="min-h-full" />
          </div>
          <div className="col-span-2 my-4 ">
            <form onSubmit={handleSubmit} className="">
              {count === 0 ? (
                <UserTypeScreen handleChange={handleChange} />
              ) : count === 1 ? (
                <Login
                  password={password}
                  name={userName}
                  handleChange={handleChange}
                  showEnterPass={showEnterPass}
                />
              ) : count === 2 ? (
                <OtpScreen
                  heading={"OTP Verification"}
                  setOtp={setOtp}
                  handleLoginWithOtp={handleLoginWithOtp}
                  mobile={userName}
                />
              ) : count === 3 && user === "New" ? (
                <SetPassword
                  name={authentication.userName}
                  handleChange={handleChange}
                />
              ) : count === 4 ? (
                <ForgotPassword handleChange={handleChange} />
              ) : (
                <h1>End</h1>
              )}
              {showEnterPass && count !== 2 && (
                <p
                  className="text-[#101942] cursor-pointer mb-[15px]"
                  onClick={() => {
                    setShowEnterPass(false);
                    setCallForgotPasswordApi(true);
                  }}
                >
                  Forgot Password ?
                </p>
              )}
              <button
                type="submit"
                className="bg-[#101942] text-[#fff] py-3 md:py-2 px-24 rounded-md m-auto flex justify-center relative mt-2 lg:w-[55%] "
              >
                Proceed
                {/* <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#586084] text-xl" /> */}
                <span className="bg-[#586084] p-[8px] rounded-[100%] absolute right-3 top-[12px] md:top-[8px]">
                  <img src="../right-arrow.png" className="w-[8px] h-[10px]" />
                </span>
              </button>
              {showEnterPass && count !== 2 && (
                <div
                  className="text-[#101942] cursor-pointer mb-2 mt-2"
                  onClick={handleLoginWithOtp}
                >
                  Login with OTP
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {showMessage && (
        <ToastContainer
          pauseOnHover={true}
          autoClose={2000}
          position="top-right"
        />
      )}
    </>
  );
};

export default Auth;
