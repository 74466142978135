import React from "react";
import Home from "../components/home/Home";
import Auth from "../components/user/auth/auth";
import ForgotPassword from "../components/user/auth/login/ForgotPassword";
// import Login from "../components/user/auth/login/Login";
import SignUp from "../components/user/auth/signup/SignUp";

export const publicRoutes = [
  {
    path: "",
    element: <Home />,
  },
  {
    path: "user/login",
    element: <Auth />,
  },
  {
    path: "user/signup",
    element: <SignUp />,
  },
  {
    path: "user/forgotpwd",
    element: <ForgotPassword />,
  },
];
