import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../utils/Header/Header";
import SideBar from "../utils/SideBar/SideBar";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const UserLayout = () => {
  const [isLoggedIn] = useState(localStorage.getItem("token"));
  const [hideSidebar, setHideSidebar] = useState(true);
  const mobileScreen = useMediaQuery({ query: `(max-width: 440px)` });
  const location = useLocation();
  console.log(location, "loc");

  useEffect(() => {
    if (mobileScreen) {
      // you don't need return statement here.
      setHideSidebar(false);
    }
  }, [mobileScreen]);

  if (!isLoggedIn) {
    return <Navigate to="/user/login" />;
  }
  return (
    <>
      <div className="grid  md:grid-cols-4 lg:grid-cols-5 ">
        <SideBar
          hsidebar={hideSidebar}
          tsidebar={{ hideSidebar, setHideSidebar }}
        />
        <div
          className={`${
            hideSidebar
              ? "md:col-span-3 lg:col-span-4 md:block hidden"
              : "col-span-5"
          }`}
        >
          <Header tsidebar={{ hideSidebar, setHideSidebar }} />
          <Outlet context={{ message: "Hello" }} />
        </div>
      </div>
    </>
  );
};

export default UserLayout;
