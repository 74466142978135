import image_pi from "../../../assets/images/image_pi.png";
const Dashboard = () => {
  return (
    <>
      <div className="flex justify-center flex-col items-center p-[100px]">
        <h1 className="text-[#000] mt-5 text-2xl">Welcome</h1>
        <img src={image_pi} alt="" className="w-[40%]" />
      </div>
    </>
  );
};

export default Dashboard;
