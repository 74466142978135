import {
  combineReducers,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import translatedText from "../../services/transaleteText.service";
export const getTranslatedText = createAsyncThunk(
  "retrieve/text",
  async (id, { rejectWithValue }) => {
    try {
      const response = await translatedText.getTranslateText(id);
      return response.data;
    } catch (error) {
      console.log(error, "ee");
    }
  }
);
const getTranslatedTextSlice = createSlice({
  name: "get/translated/text",
  initialState: {
    status: "idle",
    data: [],
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTranslatedText.pending, (state) => {
        state.status = "pending";
        state.error = false;
      })
      .addCase(getTranslatedText.fulfilled, (state, action) => {
        state.error = false;
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getTranslatedText.rejected, (state, action) => {
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const translatedTextReducer = combineReducers({
  getTranslatedTextSlice: getTranslatedTextSlice.reducer,
});
export const getTranslatedTextSelector = (state) => {
  return state.translatedTextReducer.getTranslatedTextSlice;
};
export default translatedTextReducer;
