import { useState } from "react";
import { userNameRegx } from "../../../../utils/Regex/regex";
import Error from "../../../../utils/error/Error";
import { userNameErrorMessage } from "../../../../utils/errorMessages/errorMessages";
import PasswordToggle from "../../../../utils/passwordToggle/PasswordToggle";

const Login = (props) => {
  const { handleChange, showEnterPass, name, password } = props;
  const [showPassword, setShowPassord] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const togglePassword = () => {
    setShowPassord(!showPassword);
  };
  return (
    <>
      <h1 className="text-[22px] md:text-[32px] font-medium  lg:mb-[20px] xl:mb-[40px]">
        Login or Sign up
      </h1>
      <div className="my-[20px] lg:my-[20px]">
        <input
          className="p-3 pr-10 border border-[#212E6F] rounded-md w-[55%]"
          type={"text"}
          minLength={3}
          maxLength={20}
          readOnly={
            JSON.parse(localStorage.getItem("user"))?.userName ? true : false
          }
          value={name}
          placeholder="Enter Username or Mobile number"
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (e.target.value === " ") {
              e.preventDefault();
            } else if (
              !userNameRegx.test(e.target.value) ||
              !e.target.value === " "
            ) {
              handleChange(e, "login");
              setShowErrorMessage(true);
            } else {
              setShowErrorMessage(false);
              handleChange(e, "login");
            }
          }}
          required
        />
        {showErrorMessage && <Error message={userNameErrorMessage} />}
      </div>
      {showEnterPass && (
        <>
        <div className="text-left w-[55%] m-auto">
        <label htmlFor="password" className="text-[20px] mb-2 text-left">
            Enter Password
          </label>
          <div className="my-1 relative ">
            <input
              id="password"
              className="p-3 pr-10 border border-[#212E6F] rounded-md w-full"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              name="password"
              minLength={6}
              maxLength={20}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                if (e.target.value === " ") {
                  e.preventDefault();
                } else {
                  handleChange(e, "loginPass");
                }
              }}
              required
            />{" "}
            <PasswordToggle
              showPassword={showPassword}
              setShowPassword={togglePassword}
              className="cursor-pointer	"
            />
          </div>
        </div>
          
        </>
      )}
    </>
  );
};

export default Login;
