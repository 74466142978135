import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiFillRightCircle,
} from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { HiPhone, HiLocationMarker } from "react-icons/hi";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";

let ReactIcons = {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  IoIosArrowDroprightCircle,
  AiFillRightCircle,
  HiPhone,
  HiLocationMarker,
  ImLocation2,
  RxHamburgerMenu,
};
export default ReactIcons;
