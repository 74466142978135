import { useState } from "react";
import Avatars from "../../../utils/avatar/Avatars";
import CameraImg from "../../../assets/images/camera.png";
import ReactIcons from "../../../utils/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  blockSelector,
  districtSelector,
  genderSelector,
  getBlock,
  getDistrict,
  getGender,
  getSchool,
  getSchoolByUDISE,
  getState,
  schoolSelector,
  stateSelector,
  updateProfile,
  updateProfileNoImage,
} from "../../../redux/slices/profile/profileSlice";
import { useEffect } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTranslatedTextSelector } from "../../../redux/slices/translatedText/getTranslatedText";
import { name, indianMobileNumber } from "../../../utils/Regex/regex";

const Profile = () => {
  const [profilePic, setProfilePic] = useState({});
  const [base64String, setBase64String] = useState("");
  const [isProfileReupload, setIsProfileReupload] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showSchoolName, setShowSchoolName] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const userId = JSON.parse(localStorage.getItem("id"));
  const [UdiseCode, setUdiseCode] = useState("");
  const [schoolByUdise, setSchoolByUdise] = useState();
  const [districtByUdise, setDistrictByUdise] = useState();
  const [stateByUdise, setStateByUdise] = useState();
  const [blockByUdise, setBlockByUdise] = useState();
  const [showButtonText, setShowButtonText] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    stateId: "",
    districtId: "",
    schoolId: "",
    schoolName: "",
    blockId: "",
    genderId: "",
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    mobileNo: localStorage.getItem("mobileno")
      ? localStorage.getItem("mobileno")
      : userDetails?.mobileNo,
    whatsAppNo: userDetails?.whatsAppNo ? userDetails?.whatsAppNo : "",
    emailId: "",
    dob: userDetails?.dob.split("T")[0],
    profileImage: "",
    userImage: "",
  });

  const { firstName, lastName, mobileNo, emailId, dob } = state;
  const dispatch = useDispatch();

  const gendersSelector = useSelector(genderSelector);
  const statesSelector = useSelector(stateSelector);
  const districtsSelector = useSelector(districtSelector);
  const schoolsSelector = useSelector(schoolSelector);
  const blocksSelector = useSelector(blockSelector);
  const getTranslateText = useSelector(getTranslatedTextSelector);
  console.log(getTranslateText);

  const getValuesForStatesAndGender = () => {
    dispatch(getGender());
    dispatch(getState());
  };

  const handleProfilPic = (e) => {
    const imageFile = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = function () {
      setBase64String(reader.result);
      setIsProfileReupload(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    setProfilePic((prev) => ({ ...prev, selectedFile: imageFile }));
  };
  console.log(base64String);
  const handleChange = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name === "school") {
      if (e.target.value === "other") {
        setShowSchoolName(true);
      } else if (e.target.value) {
        setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      }
    }
  };

  // getBase64FromUrl(userDetails.profilePicURL)

  const handleSubmit = (e) => {
    console.log();
    e.preventDefault();
    if (
      state.emailId &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(state.emailId)
    ) {
      setShowMessage(true);
      toast.warn("Email should be in the correct format");
      return;
    }
    let formData = new FormData();
    formData.append("UserId", userId);
    formData.append(
      "StateId",
      !stateByUdise ? state.stateId[0]?.value : stateByUdise[0].value
    );
    formData.append(
      "DistrictId",
      !districtByUdise ? state?.districtId[0]?.value : districtByUdise[0].value
    );
    formData.append(
      "SchoolId",
      !schoolByUdise ? state?.schoolId[0]?.value : schoolByUdise[0].value
    );
    formData.append(
      "BlockId",
      !blockByUdise ? state.blockId[0]?.value : blockByUdise[0].value
    );
    formData.append("Gender", state.genderId[0]?.value);
    formData.append("FirstName", state.firstName);
    formData.append("LastName", state.lastName);
    formData.append("MobileNo", mobileNo || userDetails.mobileNo);
    formData.append("WhatsAppNo", state.whatsAppNo);
    formData.append("EmailId", emailId);
    formData.append("DOB", dob);
    formData.append("ProfileImage", "");
    formData.append("SchoolName", "");
    if (isProfileReupload) {
      formData.append("userImage", profilePic.selectedFile);
    }

    if (error) {
      setShowMessage(true);
      toast.warn("Please enter a valid mobile number");
      return;
    }
    console.log(isProfileReupload, "ssss");
    console.log(userDetails.profilePicURL, "kkkkk");
    if (isProfileReupload || !userDetails.profilePicURL) {
      dispatch(updateProfile(formData))
        .unwrap()
        .then((resp) => {
          const {
            blockId,
            districtId,
            firstName,
            lastName,
            profilePicURL,
            schoolId,
            schoolName,
            stateId,
            whatsAppNo,
            gender,
            mobileNo,
          } = resp.result.response[0];
          const getUser = JSON.parse(localStorage.getItem("user"));
          localStorage.removeItem("user");
          const user = {
            ...getUser,
            blockId: blockId,
            districtId,
            firstName,
            lastName,
            schoolId,
            schoolName,
            stateId,
            whatsAppNo,
            profilePicURL,
            gender,
            mobileNo,
          };
          localStorage.setItem("user", JSON.stringify(user));

          if (resp.result.status === 1) {
            setShowMessage(true);
            toast.success("Profile is updated successfully");
            setTimeout(() => {
              navigate("/user/dashboard");
            }, 3000);
          } else {
            setShowMessage(true);
            toast.success("Some Error Occured");
          }
        })
        .catch((error) => {
          console.log(error, "eerr");
          setShowMessage(true);
          toast.warn("Please upload your profile picture");
        });
    } else {
      dispatch(updateProfileNoImage(formData))
        .unwrap()
        .then((resp) => {
          const {
            blockId,
            districtId,
            firstName,
            lastName,
            profilePicURL,
            schoolId,
            schoolName,
            stateId,
            whatsAppNo,
            gender,
            mobileNo,
          } = resp.result.response[0];
          const getUser = JSON.parse(localStorage.getItem("user"));
          localStorage.removeItem("user");
          const user = {
            ...getUser,
            blockId: blockId,
            districtId,
            firstName,
            lastName,
            schoolId,
            schoolName,
            stateId,
            whatsAppNo,
            profilePicURL,
            gender,
            mobileNo,
          };
          localStorage.setItem("user", JSON.stringify(user));

          if (resp.result.status === 1) {
            setShowMessage(true);
            toast.success("Profile is updated successfully");
            setTimeout(() => {
              navigate("/user/dashboard");
            }, 3000);
          } else {
            setShowMessage(true);
            toast.success("Some Error Occured");
          }
        })
        .catch((error) => {
          console.log(error, "eerr");
          // setShowMessage(true);
          // toast.error("Profile is required");
        });
    }
  };
  useEffect(() => {
    getValuesForStatesAndGender();
  }, []);
  useEffect(() => {
    if (state?.stateId[0]?.value)
      dispatch(getDistrict(state?.stateId[0]?.value));
  }, [state.stateId]);

  useEffect(() => {
    if (state?.districtId[0]?.value) {
      dispatch(getBlock(state?.districtId[0]?.value));
    }
  }, [state.districtId]);

  useEffect(() => {
    if (state.stateId && state.districtId) {
      const stateId = state?.stateId[0]?.value;
      const districtId = state?.districtId[0]?.value;

      dispatch(getSchool({ stateId, districtId }));
    }
  }, [state.districtId]);

  useEffect(() => {
    if (statesSelector?.data?.length > 0) {
      const { data } = statesSelector;

      const defaultStates = data.filter(
        (item) => item?.value === Number.parseInt(userDetails?.stateId)
      );

      setState((prev) => ({ ...prev, stateId: defaultStates }));
    }
  }, [statesSelector?.data]);

  useEffect(() => {
    if (districtsSelector?.data?.length > 0) {
      const { data } = districtsSelector;

      const defaultDistrict = data.filter(
        (item) => item.value === Number.parseInt(userDetails?.districtId)
      );

      setState((prev) => ({ ...prev, districtId: defaultDistrict }));
    }
  }, [districtsSelector?.data]);

  useEffect(() => {
    if (schoolsSelector?.data?.length > 0) {
      const { data } = schoolsSelector;

      const defaultSchool = data.filter(
        (item) => item.value === Number.parseInt(userDetails?.schoolId)
      );

      setState((prev) => ({ ...prev, schoolId: defaultSchool }));
    }
  }, [schoolsSelector?.data]);

  useEffect(() => {
    if (gendersSelector?.data?.length > 0) {
      const { data } = gendersSelector;

      const defaultGender = data.filter(
        (item) => item.value === Number.parseInt(userDetails?.gender)
      );

      setState((prev) => ({ ...prev, genderId: defaultGender }));
    }
  }, [gendersSelector?.data]);

  useEffect(() => {
    if (blocksSelector?.data?.length > 0) {
      const { data } = blocksSelector;

      const defaultblock = data.filter(
        (item) => item.value === Number.parseInt(userDetails?.blockId)
      );

      setState((prev) => ({ ...prev, blockId: defaultblock }));
    }
  }, [blocksSelector?.data]);

  const applyUdise = async () => {
    if (!showButtonText) {
      try {
        const response = await dispatch(
          getSchoolByUDISE({ UdiseCode })
        ).unwrap();
        console.log(response);
        const { status } = response;
        if (status === 1) {
          const { response: apiResponse } = response;
          console.log(apiResponse);
          setSchoolByUdise([
            {
              value: apiResponse[0].schoolId,
              label: apiResponse[0].schoolName,
            },
          ]);
          setDistrictByUdise([
            {
              value: apiResponse[0].districtId,
              label: apiResponse[0].districtName,
            },
          ]);
          setBlockByUdise([
            {
              value: apiResponse[0].blockId,
              label: apiResponse[0].blockName,
            },
          ]);
          setStateByUdise([
            {
              value: apiResponse[0].stateId,
              label: apiResponse[0].stateName,
            },
          ]);
        }
      } catch (error) {}
    } else {
      setStateByUdise();
      setBlockByUdise();
      setDistrictByUdise();
      setBlockByUdise();
      setUdiseCode("");
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <>
      <div className="bg-gradient-to-b from-[#002EFF2B] to-[#0DE9FF2B] drop-shadow-[0_04px_20px_#00000021] grid grid-cols-1 md:grid-cols-6 md:gap-1 p-[20px] mt-5">
        <div className="col-span-2 bg-[#ffffffad] rounded-md md:mr-[15px] mb-5 md:mb-0 backdrop-blur-md">
          <div className="my-[20px] relative">
            <input
              accept="image/*"
              id="profile-picture"
              type={"file"}
              className="hidden"
              onChange={handleProfilPic}
            />
            <Avatars
              round={true}
              size={160}
              src={base64String ? base64String : userDetails?.profilePicURL}
            />
            <label
              htmlFor="profile-picture"
              className="absolute right-[20%] md:right-[103px] md:top-[-2px]"
            >
              <img
                src={CameraImg}
                width="66%"
                className="text-[#ffffffad]"
                alt=""
              />
            </label>
          </div>
          <div className="flex flex-row flex-wrap justify-evenly profile-form m-[50px]">
            <div className="basis-full flex flex-col text-left">
              <label htmlFor="username" className="mb-2 text-lg">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey.split("_")[1] === "username"
                    )[0].keyValue
                  : "Username"}
              </label>
              <input
                id="username"
                type="text"
                className="bg-[#F2F8FB]"
                placeholder="Enter Name"
                value={userDetails?.userName}
                readOnly
              />
            </div>
            <div className="basis-full flex flex-col text-left">
              <label htmlFor="emailid" className="my-2 text-lg">
                {getTranslateText?.data.length > 0
                  ? getTranslateText?.data?.filter(
                      (item) => item.pageKey.split("_")[1] === "email"
                    )[0].keyValue
                  : "Email Id"}
              </label>
              <input
                id="emailid"
                name="emailId"
                type="email"
                className="bg-[#F2F8FB]"
                placeholder="Enter Email id"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="col-span-4 bg-[#fff] rounded-md py-2">
          <h1 className="text-[34px] my-[21px] mx-[54px] text-left">
            Create Profile
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row flex-wrap	justify-evenly profile-form">
              <div className="basis-4/5 md:basis-2/5	flex flex-col text-left">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey.split("_")[1] === "fname"
                      )[0].keyValue
                    : " First Name "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="firstName"
                  className="bg-[#F2F8FB]"
                  type={"text"}
                  placeholder="First Name"
                  onChange={(e) => {
                    const result = e.target.value.replace(/[^a-z]/gi, "");
                    setState({ ...state, firstName: result });
                  }}
                  required
                  value={state?.firstName}
                />
                {/* {showfirstNameErrorMessage && (
                  <Error message={" First name only contains alphabet"} />
                )} */}
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey.split("_")[1] === "lname"
                      )[0].keyValue
                    : "Last Name"}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  name="lastName"
                  type={"text"}
                  className="bg-[#F2F8FB]"
                  placeholder="Last Name"
                  onChange={(e) => {
                    // const input = e.target.value;
                    // if (!input.match(name)) {
                    //   e.preventDefault();
                    //   if (state.lastName.length === 1) {
                    //     handleChange(e);
                    //   }
                    // } else {
                    //   handleChange(e);
                    // }
                    const result = e.target.value.replace(/[^a-z]/gi, "");
                    setState({ ...state, lastName: result });
                  }}
                  value={state?.lastName}
                  required
                />
                {/* {showErrorMessage && (
                  <Error message={"Last name only contains alphabet"} />
                )} */}
              </div>
              <div className="basis-4/5 md:basis-2/5	flex flex-col">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey.split("_")[1] === "dob"
                      )[0].keyValue
                    : " DOB"}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"date"}
                  className="bg-[#F2F8FB]"
                  name="dob"
                  required
                  max={currentDate}
                  title="Date of birth should be greater then 18 years"
                  value={state?.dob}
                  onChange={(e) => {
                    const enteredDate = new Date(e.target.value);
                    const currentDate = new Date();
                    const minDate = new Date(
                      currentDate.getFullYear() - 18,
                      currentDate.getMonth(),
                      currentDate.getDate()
                    );

                    if (enteredDate > minDate) {
                      // DOB is less than 18 years
                      //  setShowDobErrorMessage(true);
                    } else {
                      // DOB is greater than or equal to 18 years
                      //  setShowDobErrorMessage(false);
                      handleChange(e);
                    }
                    // handleChange(e);
                  }}
                />
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey.split("_")[1] === "gender"
                      )[0].keyValue
                    : "Gender"}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={gendersSelector?.data}
                  value={state?.genderId}
                  required
                  onChange={(selectedOption) => {
                    setState({ ...state, genderId: [selectedOption] });
                  }}
                />
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "field_phone_number"
                      )[0].keyValue
                    : "Phone Number"}
                  {/* Phone Number */}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type={"text"}
                  name="mobileNo"
                  className="bg-[#F2F8FB]"
                  placeholder="Enter phone number"
                  value={state?.mobileNo}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  // onChange={(e) => {
                  //   if (!e.target.value) {
                  //     setMobileNumberError(false);
                  //     setState({ ...state, mobileNo: "" });
                  //   } else if (!indianMobileNumber.test(e.target.value)) {
                  //     setMobileNumberError(true);
                  //     setState({ ...state, mobileNo: e.target.value });
                  //   } else {
                  //     setMobileNumberError(false);
                  //     setState({ ...state, whatsAppNo: e.target.value });
                  //   }
                  // }}
                  readOnly
                  minLength={10}
                  maxLength={10}
                  required
                />
                {/* {mobileNumberError && (
                  <p style={{ color: "red" }}>Invalid mobile number</p>
                )} */}
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "field_whatsapp_number"
                      )[0].keyValue
                    : " Whatsapp Number"}
                </label>
                <input
                  type={"text"}
                  name="whatsAppNo"
                  className="bg-[#F2F8FB]"
                  placeholder="Enter whatsapp number"
                  value={state.whatsAppNo}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setError(false);
                      setState({ ...state, whatsAppNo: "" });
                    } else if (!indianMobileNumber.test(e.target.value)) {
                      setError(true);
                      setState({ ...state, whatsAppNo: e.target.value });
                    } else {
                      setError(false);
                      setState({ ...state, whatsAppNo: e.target.value });
                    }
                  }}
                  minLength={10}
                  maxLength={10}
                />
                {error && <p style={{ color: "red" }}>Invalid mobile number</p>}
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "label_udise"
                      )[0].keyValue
                    : "School Udise Name"}
                </label>
                <div className="!flex !flex-row justify-between md:justify-start">
                  <input
                    type={"text"}
                    className="bg-[#F2F8FB] w-[80%] md:!w-[60%] mr-2"
                    placeholder="Enter udise code"
                    value={UdiseCode}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (!/^\d+$/.test(input)) {
                        e.preventDefault();
                        if (UdiseCode.length === 1) {
                          // handleChange(e);
                          setUdiseCode(e.target.value);
                        }
                      } else {
                        setUdiseCode(e.target.value);
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="bg-gradient-to-b from-[#B0BDFF] to-[#5E77F9] text-white rounded-md px-3 text-sm"
                    disabled={!UdiseCode}
                    onClick={(e) => {
                      applyUdise();
                      setShowButtonText(!showButtonText);
                    }}
                  >
                    {showButtonText ? "Reset" : "Apply"}
                  </button>
                </div>
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "field_state"
                      )[0].keyValue
                    : "State"}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={statesSelector?.data}
                  value={!stateByUdise ? state.stateId : stateByUdise}
                  required
                  onChange={(selectedOption) => {
                    setState({ ...state, stateId: [selectedOption] });
                  }}
                />
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "field_district"
                      )[0].keyValue
                    : "District"}
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Select
                  options={districtsSelector?.data}
                  value={!districtByUdise ? state?.districtId : districtByUdise}
                  required
                  onChange={(selectedOption) => {
                    setState({ ...state, districtId: [selectedOption] });
                  }}
                />
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "field_block"
                      )[0].keyValue
                    : "Block"}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={blocksSelector?.data}
                  value={!blockByUdise ? state?.blockId : blockByUdise}
                  required
                  onChange={(selectedOption) => {
                    setState({ ...state, blockId: [selectedOption] });
                  }}
                />
              </div>
              <div className="basis-4/5 md:basis-2/5	">
                <label>
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "field_school"
                      )[0].keyValue
                    : "School"}
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Select
                  options={schoolsSelector?.data}
                  value={!schoolByUdise ? state?.schoolId : schoolByUdise}
                  required
                  onChange={(selectedOption) => {
                    setState({ ...state, schoolId: [selectedOption] });
                  }}
                />
                {showSchoolName && (
                  <input
                    type={"text"}
                    name="schoolName"
                    className="bg-[#F2F8FB] mr-2 mt-2"
                    placeholder="Enter school name"
                    onChange={handleChange}
                  />
                )}
              </div>
              <div className="basis-4/5 md:basis-2/5	"></div>
              <div className="basis-4/5 md:basis-2/5	">
                <button
                  type="submit"
                  className="bg-[#101942] text-[#fff] py-3 px-24 rounded-md mt-5 flex relative m-auto md:m-1"
                >
                  {getTranslateText?.data.length > 0
                    ? getTranslateText?.data?.filter(
                        (item) => item.pageKey === "btn_proceed"
                      )[0].keyValue
                    : "Proceed"}
                  <ReactIcons.AiFillRightCircle className="absolute right-3 top-[13px] text-[#fff] text-xl" />
                </button>
              </div>
            </div>
          </form>
        </div>
        {showMessage && <ToastContainer />}
      </div>
    </>
  );
};

export default Profile;
