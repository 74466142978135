import {
  combineReducers,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { getLmsURL } from "../../services/cours.service";
export const getCourseURL = createAsyncThunk(
  "get/lms/url",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLmsURL(data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const courseSlice = createSlice({
  name: "languages",
  initialState: {
    status: "idle",
    url: "",
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseURL.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getCourseURL.fulfilled, (state, action) => {
        console.log("I am course");
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getCourseURL.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const getLmsUrlSelector = (state) => {
  return state.courseReducer.courseSlice;
};
export const courseReducer = combineReducers({
  courseSlice: courseSlice.reducer,
});
