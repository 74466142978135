export const apiEndPoints = {
  USER_TYPE: "/UserType",
  GET_LANGUAGE: "/Language",
  VALIDATE_USER: "/Login/ValidateUser",
  SEND_OTP: "/Login/SendOTP",
  VERIFY_OTP: "/Login/VerifyOTP",
  LOGIN: "/Login/UserLogin",
  GET_TOKEN: "/Login/UserAuthentication",
  LOGIN_WITH_OTP: "Login/LoginwithOTPs",
  REGISTER: "/Login/UserRegistration",
  UPDATE_PROFILE: "/Login/UpdateUsersProfile",
  UPDATE_PROFILE_NO_IMAGE: "/Login/UpdateUsersProfileNoImage",
  GET_GENDER: "/Gender",
  GET_STATES: "/State",
  GET_BLOCK: "/Block/GetBlockByDistrict",
  GET_DISTRICT_BY_STATE_ID: "/District/GetDistrictByState",
  GET_SCHOOL_BY_DISTRICT_ID: "/School/GetSchools",
  FORGOT_PASSWORD: "/Login/SetPassword",
  GET_LMS_URL: "/LMSConfiguration/GetActiveURL",
  GET_APPLICATION_TEXT:
    "/ApplicationTranslatedText/GetApplicationTextByLanguage",
  GET_SCHOOL_BY_UDISE: "/School/GetSchoolByUdiseCode",
};
