import { Navigate } from "react-router-dom";
import Profile from "../components/user/profile/Profile";
import Dashboard from "../components/user/Dashboard/Dashboard";

export const privateRoutes = [
  {
    path: "",
    element: <Navigate to={"/user/login"} replace />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
];
