import {
  languageReducer,
  userTypeReducer,
  profileReducer,
  authReducer,
  courseReducer,
  translatedTextReducer,
} from "./slices";

export const rootReducer = {
  languageReducer,
  userTypeReducer,
  profileReducer,
  authReducer,
  courseReducer,
  translatedTextReducer,
};
