import { useState } from "react";
import {
  passwordValidation,
  userNameRegx,
} from "../../../../utils/Regex/regex";
import Error from "../../../../utils/error/Error";
import {
  passwordErrorMessage,
  userNameErrorMessage,
} from "../../../../utils/errorMessages/errorMessages";
import PasswordToggle from "../../../../utils/passwordToggle/PasswordToggle";
const SetPassword = (props) => {
  const { handleChange, name } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const togglePassword = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  return (
    <div className="flex flex-col items-center">
      <label htmlFor="userName">Enter UserName</label>
      <div className="my-3 relative">
        <input
          id="userName"
          name="userName"
          className="p-3 pr-10 border border-[#212E6F] rounded-md"
          type={"text"}
          placeholder="Enter Username"
          value={name}
          minLength={3}
          maxLength={20}
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (e.target.value === " ") {
              e.preventDefault();
            } else if (
              !userNameRegx.test(e.target.value) ||
              !e.target.value === " "
            ) {
              handleChange(e, "setPassword");
              setShowErrorMessage(true);
            } else {
              setShowErrorMessage(false);
              handleChange(e, "setPassword");
            }
          }}
          required
        />
        {showErrorMessage && <Error message={userNameErrorMessage} />}
      </div>

      <label htmlFor="enterPassword">Enter Password</label>
      <div className="my-3 relative">
        <input
          id="Password"
          name="Password"
          className="p-3 pr-10 border border-[#212E6F] rounded-md"
          type={showPassword ? "text" : "password"}
          placeholder="Enter Password"
          minLength={6}
          maxLength={20}
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (e.target.value === " ") {
              e.preventDefault();
            } else {
              handleChange(e, "setPassword");
            }
          }}
          required
        />

        <PasswordToggle
          showPassword={showPassword}
          setShowPassword={() => togglePassword("password")}
        />
      </div>

      <label htmlFor="confirmPassword">Confirm Password</label>
      <div className="my-1 relative">
        <input
          id="confirmPassword"
          name="confirmPassword"
          className="p-3 pr-10 border border-[#212E6F] rounded-md"
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Enter password"
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault();
            }
          }}
          minLength={6}
          maxLength={20}
          onChange={(e) => {
            if (e.target.value === " ") {
              e.preventDefault();
            } else {
              handleChange(e, "setPassword");
            }
          }}
          required
        />
        <PasswordToggle
          showPassword={showConfirmPassword}
          setShowPassword={() => togglePassword("confirmPassword")}
        />
      </div>
    </div>
  );
};

export default SetPassword;
