import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";

function App() {
  console.log("Heyyy checking in live3");
  return (
    <div className="App">
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
