import { Navigate, Outlet } from "react-router-dom";

import Header from "../../utils/userUtils/Header";

const HomeLayout = () => {
  const isLoggedIn = localStorage.getItem("token");
  if (isLoggedIn) {
    return <Navigate to="/user/dashboard" />;
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default HomeLayout;
