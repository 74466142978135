import React from "react";
import imgURL from "../../assets/images/image_pi.png";

const Card = (props) => {
  const { color, height } = props;
  return (
    <>
      {/* <div className={`h-${height} bg-[${color}] rounded-md`} > */}
      <div className="h-[28vh] md:h-[40vh] lg:h-[55vh] xl:h-[55vh] bg-[#101942] rounded-md flex flex-col justify-center">
        <img src={imgURL} className="w-[70%] md:w-[100%] m-auto" />
      </div>
    </>
  );
};

export default Card;
