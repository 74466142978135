import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveUserTypes,
  userTypeSelector,
} from "../../../redux/slices/userType/userTypeSlice";
import Teacher from "../../../assets/images/Teacher.svg";
import Parent from "../../../assets/images/Parent.svg";
// import teacherImg from "../../../assets/images/Teacher.png";
// import vectorImg from "../../../assets/images/Vector.png";
import "./UserType.css";

const UserTypeScreen = (props) => {
  const { handleChange } = props;
  const dispatch = useDispatch();
  const userTypes = useSelector(userTypeSelector);
  console.log(userTypes.data.slice(2, 4));

  const getUserTypes = async () => {
    try {
      const dispatchUserTypes = await dispatch(retrieveUserTypes()).unwrap();
      return dispatchUserTypes;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserTypes();
    if (localStorage.getItem("id")) {
      localStorage.clear();
    }
  }, []);

  return (
    <>
      <h1 className="text-[32px] font-medium mt-[0px]">Select your profile</h1>
      <div className="my-6 flex justify-center">
        {userTypes?.data.slice(2, 4).map((userType) => (
          <div key={userType.id} className="mx-3">
            <label className="mr-2">
              <label htmlFor={userType.id} className="relative">
                <input
                  className="profile-check mr-2"
                  type={"radio"}
                  name="userType"
                  id={userType.id}
                  onChange={(e) => handleChange(e, "userType")}
                  required
                />
                {userType.name === "PARENT" ? (
                  <div className="profile-type-img">
                    <img src="../Images/parents.svg" alt="" />
                  </div>
                ) : (
                  <div className="profile-type-img">
                    <img src="../Images/child.svg" alt="" />
                  </div>
                )}
              </label>
              {/* <img src={userType.icon} alt={""} /> */}
              <p className="text-[101942] mt-5 font-semibold">
                {userType.name}
              </p>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserTypeScreen;
