import React from 'react'

const SignUp = () => {
  return (
    <div>
      Signup page
    </div>
  )
}

export default SignUp
