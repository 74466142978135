import { useState } from "react";
import ReactIcons from "../../../../utils/icons";
import { passwordValidation } from "../../../../utils/Regex/regex";
import Error from "../../../../utils/error/Error";
import { passwordErrorMessage } from "../../../../utils/errorMessages/errorMessages";
import PasswordToggle from "../../../../utils/passwordToggle/PasswordToggle";
const ForgotPassword = (props) => {
  const { handleChange } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const togglePassword = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  return (
    <div className="flex flex-col items-center">
      <label htmlFor="enterPassword">Enter Password</label>
      <div className="my-3 relative">
        <input
          id="Password"
          name="Password"
          className="p-3 pr-10 border border-[#212E6F] rounded-md"
          type={showPassword ? "text" : "password"}
          placeholder="Enter Password"
          onChange={(e) => {
            if (e.target.value === " ") {
              e.preventDefault();
            } else if (
              !passwordValidation.test(e.target.value) ||
              !e.target.value === " "
            ) {
              setShowPasswordError(true);
              handleChange(e, "forgotPassword");
            } else {
              setShowPasswordError(false);
              handleChange(e, "forgotPassword");
            }
          }}
          required
        />
        <PasswordToggle
          showPassword={showPassword}
          setShowPassword={() => togglePassword("password")}
        />
        {showPasswordError && <Error message={passwordErrorMessage} />}
      </div>
      <label htmlFor="enterPassword">Confirm Password</label>
      <div className="my-3 relative">
        <input
          id="confirmPassword"
          name="confirmPassword"
          className="p-3 pr-10 border border-[#212E6F] rounded-md"
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Enter Password"
          onChange={(e) => {
            if (e.target.value === " ") {
              e.preventDefault();
            } else if (
              !passwordValidation.test(e.target.value) ||
              !e.target.value === " "
            ) {
              setConfirmPasswordError(true);
              handleChange(e, "forgotPassword");
            } else {
              setConfirmPasswordError(false);
              handleChange(e, "forgotPassword");
            }
          }}
          required
        />

        <PasswordToggle
          showPassword={showConfirmPassword}
          setShowPassword={() => togglePassword("confirmPassword")}
        />
        {confirmPasswordError && <Error message={passwordErrorMessage} />}
      </div>
    </div>
  );
};

export default ForgotPassword;
