import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import auth from "../../services/auth.service";

const initialState = {
  status: "idle",
  data: [],
  error: false,
};

export const validateUser = createAsyncThunk(
  "validateUser",
  async (userData, { rejectWithValue }) => {
    try {
      const { userName, userTypeId } = userData;
      const res = await auth.validateUser(userName, userTypeId);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "sendOtp",
  async (mobileNo, { rejectWithValue }) => {
    try {
      const res = await auth.sendOtp(mobileNo);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (otpInfo, { rejectWithValue }) => {
    try {
      const { userName, otp } = otpInfo;
      const res = await auth.verifyOtp(userName, otp);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const userLogin = createAsyncThunk(
  "login",
  async (userData, { rejectWithValue }) => {
    try {
      console.log(userData);
      const res = await auth.login(userData);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const loginWithOtp = createAsyncThunk(
  "login/with/otp",
  async (userData, { rejectWithValue }) => {
    try {
      console.log(userData);
      const res = await auth.loginWithOtp(userData);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const userRegistration = createAsyncThunk(
  "register",
  async (userData, { rejectWithValue }) => {
    try {
      const { userName, userTypeId, Password, MobileNo } = userData;
      const res = await auth.register(userName, userTypeId, Password, MobileNo);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getTokenMiddleware = createAsyncThunk(
  "getToken",
  async (userData, { rejectWithValue }) => {
    try {
      console.log(userData, "in the token");
      const { userName, userTypeId, password } = userData;
      const res = await auth.getToken({ userName, userTypeId, password });
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "forgot/password",
  async (userData, { rejectWithValue }) => {
    try {
      const { Password } = userData;

      const res = await auth.forgotPass(Password);
      console.log(res, "in the resssss");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
const validateUserSlice = createSlice({
  name: "validateUser",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(validateUser.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(validateUser.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(validateUser.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const sendOtpSlice = createSlice({
  name: "sendOtp",
  initialStateSendOtp: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialStateVerifyOtp: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const userLoginSlice = createSlice({
  name: "login",
  initialStateVerifyOtp: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const loginWithOtpSlice = createSlice({
  name: "login",
  initialStateVerifyOtp: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithOtp.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(loginWithOtp.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(loginWithOtp.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const userRegistrationSlice = createSlice({
  name: "register",
  initialStateRegister: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(userRegistration.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(userRegistration.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(userRegistration.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
const getTokenSlice = createSlice({
  name: "get/token",
  initialStateRegister: {
    status: "idle",
    data: [],
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTokenMiddleware.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getTokenMiddleware.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getTokenMiddleware.rejected, (state, action) => {
        state.status = "failed";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    status: "idle",
    message: "",
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "password updated successfully";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = "success";
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const validateUserSelector = (state) => {
  return state.authReducer.validateUserSlice;
};
export const sendOtpSelector = (state) => {
  return state.authReducer.sendOtpSlice;
};
export const verifyOtpSelector = (state) => {
  return state.authReducer.verifyOtpSlice;
};
export const userLoginSelector = (state) => {
  return state.authReducer.userLoginSlice;
};
export const loginWithOtpSelector = (state) => {
  return state.authReducer.loginWithOtpSlice;
};
export const forgotPasswordSelector = (state) => {
  console.log(state, "state");
  return state.authReducer.forgotPasswordSlice;
};
const authReducer = combineReducers({
  validateUserSlice: validateUserSlice.reducer,
  sendOtpSlice: sendOtpSlice.reducer,
  verifyOtpSlice: verifyOtpSlice.reducer,
  userLoginSlice: userLoginSlice.reducer,
  loginWithOtpSlice: loginWithOtpSlice.reducer,
  userRegistrationSlice: userRegistrationSlice.reducer,
  forgotPasswordSlice: forgotPasswordSlice.reducer,
  getTokenSlice: getTokenSlice.reducer,
});

export default authReducer;
