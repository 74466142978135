import { useEffect, useState } from "react";
import image_pi from "../../assets/images/image_pi.png";
import { useDispatch, useSelector } from "react-redux";
import {
  faAngleRight,
  faPowerOff,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "./SideBarMenu";
import { Link, useNavigate } from "react-router-dom";
import { getLmsUrlSelector } from "../../redux/slices/course/courseSlice";
import { getCourseURL } from "../../redux/slices/course/courseSlice";
import "./Sidebar.css";
import { getTranslatedTextSelector } from "../../redux/slices/translatedText/getTranslatedText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SideBar = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course = useSelector(getLmsUrlSelector);
  const getTranslatedText = useSelector(getTranslatedTextSelector);
  console.log(course);
  const { hideSidebar, setHideSidebar } = props.tsidebar;
  useEffect(() => {
    // const { userName } = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("id");

    dispatch(getCourseURL({ userId: userId, token, languageId: "english" }));
  }, []);

  const mCloseSidebar = () => {
    setHideSidebar(!hideSidebar);
  };
  const getText = (element) => {
    const text = getTranslatedText?.data?.filter(
      (item) => item.pageKey === element
    );
    return text[0].keyValue;
  };

  const isProfileCompleted = () => {
    if (
      JSON.parse(localStorage.getItem("user"))?.blockId &&
      JSON.parse(localStorage.getItem("user"))?.dob &&
      JSON.parse(localStorage.getItem("user"))?.gender &&
      JSON.parse(localStorage.getItem("user"))?.lastName &&
      JSON.parse(localStorage.getItem("user"))?.stateId &&
      JSON.parse(localStorage.getItem("user"))?.userName &&
      JSON.parse(localStorage.getItem("user"))?.firstName &&
      JSON.parse(localStorage.getItem("user"))?.mobileNo &&
      JSON.parse(localStorage.getItem("user"))?.districtId &&
      JSON.parse(localStorage.getItem("user"))?.schoolId
    ) {
      return true;
    }
  };
  console.log(`${course?.data?.response?.platformUrl}&allowhome=1`);
  return (
    <>
      <div className={`${props.hsidebar ? "sblock" : "shidden"}`}>
        <div className="flex ">
          <div className="bg-[#0C1A61] h-screen relative">
            <div className="flex justify-center relative mt-10 md:mt-0">
              <img src={image_pi} className="w-1/2" alt="" />
              <FontAwesomeIcon
                icon={faXmark}
                className="md:hidden absolute right-5 text-[#fff] top-[-20px] text-xl"
                onClick={mCloseSidebar}
              />
            </div>
            <div className="px-[30px]">
              {Menu.map((item, index) => (
                <Link
                  onClick={() => {
                    if (!isProfileCompleted()) {
                      setShowMessage(true);
                      toast.warn("Please completed the profile first");
                    }
                  }}
                  className="flex justify-between my-3 items-center cursor-pointer"
                  key={index}
                  to={
                    isProfileCompleted()
                      ? item.path
                        ? `${course?.data?.response?.platformUrl}&allowhome=1`
                        : "/user/dashboard"
                      : "/user/profile"
                  }
                >
                  <div className="flex">
                    <img src={item.icon} alt="" className="mr-[10px]" />
                    <p className="text-left text-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 mr-10">
                      {" "}
                      {/* {item.title} */}
                      {getTranslatedText?.data.length > 0
                        ? item.title === "Courses"
                          ? getText("item_courses")
                          : item.title === "Dashboard"
                          ? getText("item_home")
                          : item.title === "Certificate"
                          ? getText("certificate")
                          : item.title === "Screening"
                          ? getText("screening")
                          : item.title === "Settings"
                          ? getText("item_settings")
                          : item.title
                        : item.title}
                    </p>
                  </div>
                  {/* <FontAwesomeIcon icon={item.icon} color="white" /> */}

                  <FontAwesomeIcon
                    icon={faAngleRight}
                    color="white"
                    //   className="text-2xl"
                  />
                </Link>
              ))}
            </div>
            <div className="flex justify-center  bg-[#ffffff29] h-10 items-center  mx-6 rounded-lg absolute bottom-[30px] w-[80%]">
              <img
                src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
                alt="..."
                className="rounded-full max-w-full h-auto align-middle border-none w-5 h-5"
              />
              <div
                className="flex ml-16 cursor-pointer hover:bg-white group hover:rounded-lg p-1 "
                onClick={() => {
                  localStorage.clear();
                  navigate("/user/login");
                }}
              >
                {/* <img
                  src={logout}
                  alt=""
                  className=" group-hover:fill-bg-sky-600 mr-[5px] w-[10px] "
                /> */}
                <FontAwesomeIcon
                  icon={faPowerOff}
                  // style={{ color: "#ffffff" }}
                  className="text-[#fff] group-hover:text-[#0F1A4A] w-[10px] mr-[5px] self-end"
                />
                <p className="text-white group-hover:text-[#0F1A4A] text-[12px] font-medium">
                  Logout
                </p>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default SideBar;
