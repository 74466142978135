import home from '../../assets/images/home.png'
import courses from '../../assets/images/courses.png'
import certificate from '../../assets/images/certificate.png'
import settings from '../../assets/images/settings.png'
import screening from '../../assets/images/screening.png'
export const Menu = [
    {

        title: "Dashboard",
        icon: home,

    },
    {
        title: "Courses",
        icon: courses,
        path: '/user/course',

    },
    {

        title: "Certificate",
        icon: certificate,

    },
    {

        title: "Screening",
        icon: screening,

    },
    {

        title: "Settings",
        icon: settings,

    },
]